<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading === true"></three-bar-loader>
    <div *ngIf="isLoading === false && showError === true">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-center mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="previousPage()">Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <div class="mdc-typography--headline2">
        <p class="text--align-center">Claims Report</p>
      </div>
      <span class="blank-line blank-line-2 d-block"></span>
      <div class="grid__inner">
        <!-- <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <div *ngIf="useServiceProviderFilter">
            <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
              <mat-label>Select a service provider:</mat-label>
              <mat-select [(ngModel)]="selectedServiceProviderIds" (ngModelChange)="selectOption($event)" #clinicSelect
                multiple>
                <mat-option *ngFor="let cl of serviceProviders" [value]="cl.ocsaname">{{cl.workflowName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div> -->

        <!--Events Name-->
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <div *ngIf="useServiceProviderFilter">
            <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
              <mat-label>Select the Event:</mat-label>
              <mat-select [(ngModel)]="selectedEventNames" (ngModelChange)="selectOption($event)" #EventSelect multiple>
                <mat-option *ngFor="let event of eventNames"
                  [value]="event.description">{{event.description}}</mat-option>
              </mat-select></mat-form-field>
          </div>
        </div>
        <!-- Event Name-->

        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <div *ngIf="useServiceProviderFilter">
            <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
              <mat-label>Enter ID Number:</mat-label>
              <input matInput placeholder="Filter by ID Number" (keyup)="applyFilter($event)" />
            </mat-form-field>
          </div>
        </div>

        <!-- Date Range -->
        <div class="grid__cell--span-3-desktop grid__cell--span-all">
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRangePicker" [min]="dateRangeLimit"
              [max]="today">
              <input matStartDate placeholder="Start date" #dateRangeStart formControlName="dateRangeStart"
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
              <input matEndDate placeholder="End date" #dateRangeEnd formControlName="dateRangeEnd"
                (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <!-- Date Range -->

        <div class="row">
          <div class="col-md-6">
            <div class="grid__cell--span-5-desktop grid__cell--span-all">
              <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
                (click)="btnSearch_Click()">
                Search
              </button>
            </div>
          </div>
          <div class="grid__cell text--align-right downloadbutton">
            <button class="mat-fab download-button mdc-button__fill--darkblue" (click)="downloadReport()">
              <span class="mat-button-wrapper">
                <i role="img" class="material-icons">download</i>
              </span>
            </button>
            <span>Download to Excel</span>
          </div>
        </div>
      </div>
      <!-- ///////////////////////////////////////////////////////////////////////// -->
      <div class="table-wrapper">
        <table class="mdc-typography--body2" mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="PatientRefNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Number </th>
            <td mat-cell *matCellDef="let element">
              <div class="nowrap">{{element.patientRefNumber}}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="CompanyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name </th>
            <td mat-cell *matCellDef="let element"> {{element.companyName
              }} </td>
          </ng-container>
          <ng-container matColumnDef="DateofService">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Service </th>
            <td mat-cell *matCellDef="let element"> {{element.dateOfService}} </td>
          </ng-container>
          <ng-container matColumnDef="PatientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Patient Name </th>
            <td mat-cell *matCellDef="let element"> {{element.patientFirstName}} </td>
          </ng-container>
          <ng-container matColumnDef="PatientSurname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Patient Surname </th>
            <td mat-cell *matCellDef="let element"> {{element.patientSurname}} </td>
          </ng-container>
          <ng-container matColumnDef="SchemeCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Member Number </th>
            <td mat-cell *matCellDef="let element"> {{element.memberNumber}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page"></mat-paginator>
      <div *ngIf="searchClicked && dataSource.data.length === 0" class="no-data-message">
        We're sorry. We are not able to find results matching your search criteria. Please check the filters you
        have selected.
      </div>
      <div *ngIf="!searchClicked && dataSource.data.length === 0" class="no-data-message">
        No data to display
      </div>

    </div>
  </article>
</div>