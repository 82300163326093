import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClaimResponse } from 'src/app/shared/interfaces/claimParams';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-edit-member-dailog',
  templateUrl: './edit-member-dailog.component.html',
  styleUrls: ['./edit-member-dailog.component.scss']
})
export class EditMemberDailogComponent implements OnInit {
  editForm: any;
  userId: any;
  isLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditMemberDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalData: GlobalDataService,
    private claimservicce: ClaimsService
  ) {
    this.globalData.userFullNameVal.subscribe(d => this.userId = d);
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      memberNumber: [this.data.memberNumber || ''],
      dependantCode: [this.data.dependantCode || ''],
      schemeCode: [this.data.mediscorPcncode?.slice(0, -2) || '']
    });
  }

  async onSave() {
    if (this.editForm.valid) {
      let data = this.editForm.getRawValue();
      let obj: ClaimResponse = {
        Id: this.data.id,
        MemberNumber: data.memberNumber,
        DependantCode: data.dependantCode,
        MediscorPcncode: data.schemeCode,
        Username: this.userId
      }
      this.isLoading = true;
      await this.claimservicce.UpdateIntoClaimResponse(obj).then(data => {
        console.log("Ping - Update Claim Response", data);
        this.dialogRef.close(this.editForm.value);
        this.isLoading = false;
      }).catch(error => {
        console.log("Error - Update Claim Response", error);
        this.dialogRef.close(this.editForm.value);
        this.isLoading = false;
      })
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

}
