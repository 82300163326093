import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-claim-by-company',
  templateUrl: './claim-by-company.component.html',
  styleUrls: ['./claim-by-company.component.scss']
})
export class ClaimByCompanyComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading = false;
  userId: any;
  claimCompanyName: any = [];
  selectedCompanyNames: any;
  filteredDropDownList: any;
  listOfCompanyNames: any;
  gridData: any = [];
  claimAllCompany: any = [];
  pagesize = 0;
  dataSource = new MatTableDataSource<any>(this.claimAllCompany);
  displayedColumns: string[] = ['eventDate', 'companyName', 'eventConsultationNum', 'action'];

  constructor(private claimService: ClaimsService, private router: Router, private route: ActivatedRoute, private globalData: GlobalDataService) {
    this.globalData.userIdVal.subscribe(d => this.userId = d);
    this.globalData.updateBackRoute('/wellness-event/corporate/claim-landing');
  }

  ngOnInit(): void {
    this.getClaimCompany();
    this.getClaimAllCompany();
  }


  async getClaimAllCompany() {
    this.isLoading = true;
    await this.claimService.GetAllClaimByCompany().then(data => {
      console.log("Ping - Claims Data", data);
      this.pagesize = data.length;
      this.gridData = data;
      this.claimAllCompany = new MatTableDataSource<any>(data);
      this.filteredDropDownList = data.filter(item1 =>
        this.claimCompanyName.some(item2 => item2.id === item1.id)
      )
      this.listOfCompanyNames = this.filteredDropDownList;
      console.log("Ping - Claims compnay name--1", this.filteredDropDownList);
      setTimeout(() => this.claimAllCompany.paginator = this.paginator);
      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  async getClaimCompany() {
    this.isLoading = true;
    await this.claimService.GetAllCompanyName().then(data => {
      console.log("Ping - Claims compnay name", data);
      this.claimCompanyName = data;
      this.filteredDropDownList = data.filter(item1 =>
        this.gridData.some(item2 => item2.id === item1.id)
      )
      this.listOfCompanyNames = this.filteredDropDownList.sort((a, b) =>
        a.description.localeCompare(b.description));
      console.log("Ping - Claims compnay name--2", this.filteredDropDownList);
      this.isLoading = false;
    }).catch(err => {
      console.log(err);
      this.isLoading = false;
    });
  }

  selectOption(event: any) {
    console.log(event.value);
    this.selectedCompanyNames = event.value;
  }

  searchClaim() {
    console.log("Process Claim");
    if (this.selectedCompanyNames.length > 0) {
      console.log("Selected Company", this.gridData);
      let filterGridData = this.gridData.filter(x => this.selectedCompanyNames.includes(x.id));
      this.pagesize = filterGridData.length;
      this.claimAllCompany = new MatTableDataSource<any>(filterGridData);
      setTimeout(() => this.claimAllCompany.paginator = this.paginator);
    }
    else {
      this.pagesize = this.gridData.length;
      this.claimAllCompany = new MatTableDataSource<any>(this.gridData);
      setTimeout(() => this.claimAllCompany.paginator = this.paginator);
      //alert("Please select a company");
    }
  }

  claim(event: any) {
    this.isLoading = true;
    this.claimService.processClaim(event.id, this.userId).then(data => {
      console.log("Claim process done", data);
      this.getClaimCompany();
      this.getClaimAllCompany();
      this.isLoading = false;
      Swal.fire({
        icon: 'success',
        title: 'You will receive a claim report email shortly',
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
      })
    }, err => {
      console.log(err);
      this.isLoading = false;
    })
      .catch(err => {
        console.log(err);
        this.isLoading = false;
      });
  }

  goToClaimLanding() {
    this.router.navigate(['../landing'], { relativeTo: this.route });
  }

}
