// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let getDomain = location.origin;
let apiDomain = '';
let thirdPartyApiDomain = '';
let apiDomainQframe = '';
let keycloakRealm = "Wellness";
let keycloakUrl = "https://test.mhsauth.co.za";

const isLocal = getDomain.indexOf('localhost:4200') > -1;
const isPROD = getDomain.indexOf('perigon.momentumhealthsolutions.co.za') > -1;
const isDEV = getDomain.indexOf('perigon-dev.momentumhealthsolutions.co.za') > -1;
const isTST = getDomain.indexOf('perigon-test.momentumhealthsolutions.co.za') > -1;
const isPRE = getDomain.indexOf('perigon-pre.momentumhealthsolutions.co.za') > -1;
const isLekker = false;
if (isPROD) {
  keycloakRealm = "Wellness";
  keycloakUrl = "https://mhsauth.co.za";
}

if (getDomain.indexOf('dev') > -1 || getDomain.indexOf('localhost') > -1) {
  getDomain = 'https://perigonapi-dev.momentumhealthsolutions.co.za/api';
}


if (isPROD) {
  apiDomain = 'https://perigonapi.momentumhealthsolutions.co.za/api';
  apiDomainQframe = 'https://qframeapi.momentumhealthsolutions.co.za/api';
  thirdPartyApiDomain = 'https://prod-thirdpartyapi.momentumhealthsolutions.co.za/api';
} else if (isDEV) {
  apiDomain = 'https://perigonapi-dev.momentumhealthsolutions.co.za/api';
  apiDomainQframe = 'https://qframeapi-dev.momentumhealthsolutions.co.za/api';
  thirdPartyApiDomain = 'https://dev-thirdpartycall.momentumhealthsolutions.co.za/api';
} else if (isLocal && isLekker) {
  apiDomain = 'https://localhost:5001/api';
  apiDomainQframe = 'https://localhost:27697/api';
  thirdPartyApiDomain = 'http://localhost:48304/api';
} else if (isLocal && !isLekker) {
  apiDomain = 'https://perigonapi-dev.momentumhealthsolutions.co.za/api';
  apiDomainQframe = 'https://qframeapi-dev.momentumhealthsolutions.co.za/api';
  thirdPartyApiDomain = 'https://dev-thirdpartycall.momentumhealthsolutions.co.za/api';
} else if (isTST) {
  apiDomain = 'https://perigonapi-tst.momentumhealthsolutions.co.za/api';
  apiDomainQframe = 'https://qframeapi-tst.momentumhealthsolutions.co.za/api';
  thirdPartyApiDomain = 'https://dev-thirdpartycall.momentumhealthsolutions.co.za/api';
} else if (isPRE) {
  apiDomain = 'https://perigonapi-pre.momentumhealthsolutions.co.za/api';
  apiDomainQframe = 'https://qframeapi-pre.momentumhealthsolutions.co.za/api'; keycloakRealm = "Wellness";
  keycloakUrl = "https://mhsauth.co.za";
} else {
  apiDomain = 'https://perigonapi-dev.momentumhealthsolutions.co.za/api';
  apiDomainQframe = 'https://qframeapi-dev.momentumhealthsolutions.co.za/api';
  thirdPartyApiDomain = 'https://dev-thirdpartycall.momentumhealthsolutions.co.za/api';
}
export const environment = {
  production: isPROD,
  apiUrl: getDomain + '/api/v1',
  healthHubGatewayUser: 'exchange',
  healthHubGatewayPwd: 'Exchange@1',
  apiDomain: apiDomain,
  thirdPartyApiDomain: thirdPartyApiDomain,
  apiDomainQframe: apiDomainQframe,
  keycloakRealm: keycloakRealm,
  keycloakUrl: keycloakUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
