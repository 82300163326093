<div *ngIf="currentRoute !== '/landing'">
  <aside id="motion-main-drawer" class="mdc-drawer mdc-drawer--modal" [ngClass]="{ 'mdc-drawer--open': menuOpen}">
    <div class="mdc-drawer__content">
      <nav id="motion-main-drawer__list" class="mdc-list">
        <a id="portal-placeholder-link" class="mdc-list-item d-none"></a>
        <div class="mdc-drawer__close"><a (click)="toggleMenu()" class="material-icons" id="motion-navclose">close</a>
        </div>

        <div class="mdc-list-divider"></div>


        <a [routerLink]="['/app-eapa']" class="mdc-list-item">
          <span class="mdc-list-item__text">Conferencing</span>
        </a>
        <div class="mdc-list-divider"></div>
        <a [routerLink]="['/sp-landing']" class="mdc-list-item">
          <span class="mdc-list-item__text">Provider portal</span>
        </a>
        <div class="mdc-list-divider"></div>
        <a [routerLink]="['/wellness-event/saps/landing']" class="mdc-list-item">
          <span class="mdc-list-item__text">SAPS wellness events</span>
        </a>
        <div class="mdc-list-divider"></div>
        <a [routerLink]="['/wellness-event/adhoc/landing']" class="mdc-list-item">
          <span class="mdc-list-item__text">Adhoc wellness events</span>
        </a>
        <div class="mdc-list-divider"></div>
        <a [routerLink]="['/wellness-event/corporate/landing']" class="mdc-list-item">
          <span class="mdc-list-item__text">Corporate wellness events</span>
        </a>
        <div class="mdc-list-divider"></div>
        <a *ngIf="correctionsAccess" [routerLink]="['/support-landing']" class="mdc-list-item">
          <span class="mdc-list-item__text">Corrections</span>
        </a>
        <div *ngIf="correctionsAccess" class="mdc-list-divider"></div>
        <!--<a [routerLink]="['/appointment']" [disabled]="true" class="mdc-list-item mdc-list-item--disabled">-->
        <a [disabled]="true" class="mdc-list-item mdc-list-item--disabled">
          <span class="mdc-list-item__text">Occupational health</span>
        </a>
        <div class="mdc-list-divider"></div>
        <!--<a [routerLink]="['/eap']" [disabled]="true" class="mdc-list-item mdc-list-item--disabled">-->
        <a [disabled]="true" class="mdc-list-item mdc-list-item--disabled">
          <span class="mdc-list-item__text">EAP</span>
        </a>
        <div class="mdc-list-divider"></div>
        <span class="mdc-list-group__subheader">Surveys</span>
        <!--<a [routerLink]="['/wcg', '221847E5-88F3-46B7-AC2A-D16EA464E804']" [disabled]="true" class="mdc-list-item mdc-list-item--disabled">-->
        <div class="mdc-list-divider"></div>
        <a class="mdc-list-item" [routerLink]="[eapClientSurveyLink]">
          <span class="mdc-list-item__text">EAP Client satisfaction survey</span>
        </a>
        <a class="mdc-list-item" [routerLink]="[crmSatisfactionSurveyLink]">
          <span class="mdc-list-item__text">CRM satisfaction survey</span>
        </a>
        <div class="mdc-list-divider"></div>

        <a class="mdc-list-item" [routerLink]="[sapsWorkplaceWellnessSurveyLink]">
          <span class="mdc-list-item__text">SARS Workplace Wellness Survey</span>
        </a>
        <div class="mdc-list-divider"></div>
        <a class="mdc-list-item" [routerLink]="[sapsWellnessDaySurveyLink]">
          <span class="mdc-list-item__text">SAPS Wellness day satisfaction survey</span>
        </a>
        <div class="mdc-list-divider"></div>

        <a href="mailto:Ithelpdesk@momentum.co.za?Subject=Perigon%20support%20request" class="mdc-list-item">
          <span class="mdc-list-item__text">Contact us</span>
        </a>
        <a (click)="logout()" class="mdc-list-item">
          <span class="mdc-list-item__text">Log out</span>
        </a>

        <div class="mdc-list-divider"></div>

      </nav>

    </div>
  </aside>
  <div class="mdc-drawer-scrim"></div>
  <header *ngIf="!hideMenu || isMental" id="motion-top-app-bar"
    class="mdc-top-app-bar mdc-top-app-bar--fixed mdc-top-app-bar--dense" aria-label="Perigon site header">
    <div id="motion-top-bar-left" class="mdc-top-app-bar__col">
      <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
        <a href="javascript:void(0)"
          class="material-icons mdc-top-app-bar__navigation-icon mdc-ripple-upgraded mdc-ripple-upgraded--unbounded"
          aria-haspopup="true" (click)="toggleMenu()"
          style="--mdc-ripple-fg-size:2px; --mdc-ripple-fg-scale:1.71429; --mdc-ripple-left:10px; --mdc-ripple-top:20px;">menu</a>
        <div class="motion-top-bar-left d-block-desktop d-none scroll-fadein" style="padding-left: 20px;">
          <img src=".\assets\img\mom-perigon-logo-simplified.svg" height="24" title="Perigon logo" />
        </div>
      </section>
    </div>
    <!-- <div id="motion-top-bar-left" class="mdc-top-app-bar__col">
      <a style="padding-left: 0;padding-right: 0;" [routerLink]="['/wellness-event-booking']" [state]="{mode:'manage'}"
        target="_self" aria-label="Back to calendar">
        <span class="mdc-button mdc-button--raised motion-button--small mdc-ripple-upgraded">Back to calendar</span>
      </a>
    </div> -->
    <div id="motion-top-bar-left" class="mdc-top-app-bar__col">
      <app-back-button></app-back-button>
      <div class="motion-topbar-logo d-block-desktop d-none scroll-fadein">
        <img src=".\assets\img\momentum-wellness-logo.svg" height="48" style="padding-top: 2px;"
          title="Wellness logo" />
      </div>
    </div>

    <div id="motion-top-bar-right-container" class="mdc-top-app-bar__col">
      <div id="motion-top-bar-right" class="mdc-top-app-bar__col d-table" role="menu">
        <!-- Motion Top Right Navigation Links: -->
        <!-- <app-booking-calendar-button></app-booking-calendar-button> -->
        <a *ngIf="!hideCalendarButton" class="mdc-top-app-bar__toplinks d-table-cell" (click)="redirectToEvent()"
          [state]="{mode:'create'}" title="Wellness Event Booking">Event
          Booking</a>
        <a class="mdc-top-app-bar__toplinks d-table-cell" role="menuitem" title="Contact Perigon Support"
          href="mailto:Ithelpdesk@momentum.co.za?Subject=Perigon%20support%20request">Contact us</a>

        <ng-container *ngIf="isLoggedIn; then loggedIn; else loggedOut"></ng-container>
        <ng-template #loggedOut>
          <span role="menu">
            <a href="javascript:void(0);" (click)="login()" id="app-bar-login"
              class="mdc-top-app-bar__toplinks d-table-cell" role="menuitem" title="Log in to your account">Log In</a>
          </span>
        </ng-template>
        <ng-template #loggedIn>
          <app-profile-menu></app-profile-menu>
        </ng-template>
      </div>
    </div>
  </header>
</div>

<div *ngIf="isLoggedIn" id="motion-main-content"
  class="mdc-top-app-bar--dense-fixed-adjust mdc-drawer-app-content mat-app-section-spacing"
  style="padding-bottom: 0px;">
  <router-outlet> </router-outlet>
</div>


<app-online-icon></app-online-icon>



<div *ngIf="currentRoute == '/landing'">
  <footer id="motion-footer">
    <div class="grid">
      <div *ngIf="!isLoggedIn">
        <br><br><br><br>
      </div>
      <div class="grid__inner grid__inner--layout-444 text--align-center section-spacing--tier-3">
        <div class="grid__cell">
        </div>
        <div class="grid__cell">
          <img src=".\assets\img\mom-perigon-logo.svg" height="120" title="Perigon logo" />
        </div>
      </div>

    </div>
  </footer>
</div>