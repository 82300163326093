//import { MatDateFormats } from '@angular/material/core/datetime/date-formats';

import { environment } from '../../environments/environment'

export let HEALTH_HUB_GATEWAY_USER = environment.healthHubGatewayUser

export let HEALTH_HUB_GATEWAY_PASSWORD = environment.healthHubGatewayPwd

export let API_PATH = environment.apiDomain
export let API_PATH_QFRAME = environment.apiDomainQframe
export let THIRD_PARTY_API_PATH = environment.thirdPartyApiDomain

export let CAMPAIGN = 'https://momentummedicalscheme.co.za/wp-json/acf/v3/posts'

export enum EXAMINATIONRESULT {
  DONE = 0,
  ABNORMAL = 1,
  NOTDONE = 2,
  CONTRAINDICATED = 3,
  AWAITINGRESULTS = 4,
  NOTREGISTERED = 5,
  CONSENTGIVEN = 6,
  CONSENTREFUSED = 7,
  TESTREFUSED = 8,
}

export enum FITNESSRECOMMENDATION {
  FITFORDUTY = 0,
  FITFURTHERREVIEW = 1,
  FITEXCLUSIONS = 2,
  TEMPORARILYUNFIT = 3,
  UNFIT = 4,
}

//


export enum LISTS {
  SAPS_RANKS = '81B4A1FE-90BA-4FEF-8A89-62A989C27734',
  ALLOCATED_EQUIPMENT = 'F3452AFD-A553-4E5C-B203-636E586F205C',
  GROUP_PRODUCTS = '90A646B9-62AB-48F4-BFA9-51CACEF994F1',
  WELLNESS_ASSESSMENT_OPTIONS = '9D5A7FA2-3B34-43AD-8849-1DD8635484F8',
  //GENDERS = 'DC72C63B-F8ED-49D2-9CC3-DBF2178DFF87',
  GENDERS = 'DD46119C-2F05-49B6-84BA-82F4DAD1BBD7',
  EVENT_ATMOSPHERES = 'D52ACB0B-4D31-4040-9019-53A71344288C',
  PROVINCES = '6F23D362-80BD-41D8-A6BC-FA37CEF92F7D',
  CITIES = '969EB4A4-F9A8-4531-A54E-94DC7FD65B1F',
  SERVICE_TYPES = '75E88F50-2072-4342-95CD-82DAE12B2E56',
  MEDICAL_AIDS = '081D737F-85B3-4FED-B892-8C2902D8A424',
  LANGUAGES = 'B77A6A4A-5E9C-43FA-A270-7196D300B223',
  MARITAL_STATUSES = 'BD2A0B67-F558-4F65-B36A-AB22CADF2568',
  EMPLOYMENT_ACTS = '03D8B2E4-84FD-4696-B108-7374684E8414',
  HP_CERTIFICATION_TYPE = '69999787-FD3A-427F-BBEF-806AC0ADA7E2',
  EVENT_STATUSES = "73779F0A-761A-41AC-9E17-23FB65B7ABFE",
  HEALTH_PRACTITIONER_TYPES = '5BEDC80F-F989-419B-B11B-01A38D14AB0C',
  PROVIDER_TYPES = '6977E59C-DE65-49A0-97A2-54258D83E445',
  YES_NO = '7A7A94B9-5D39-4DC0-A454-36043FC39108',
  COUNTRIES = '3F2504E0-4F89-11D3-9A0C-0305E82C3301',
  PAYMENT = '1C923881-9827-494D-814B-409DB5065DDA',
  COORDINATOR_NAMES = '766C3E74-622E-4766-9023-35CC10780F7B'
}

export enum EVENT_STATUS_IDS {
  EXECUTED = '120ad178-d3c5-4361-9547-484d8e6b3a35',
  CANCELLED = '377d722b-7400-4160-98e8-f7084ce2a7e6',
  BOOKED = 'ddbb6282-ecaa-455b-9357-42ce9ca44325',
  PLANNED = '256b8a8e-77fc-4e9d-ac3d-43302c442192'
}

export enum ROLES {
  BASIC = 'Basic',
  ADMINISTRATOR = 'Administrator',
  COORDINATOR = 'Coordinator',
  REGISTRAR = 'Registrar',
  CAPTURER = 'Capturer',
  SERVICE_PROVIDER = 'Service provider',
  ADMIN_ASSISTANT = 'Admin assistant',
  HEALTH_PRACTITIONER = 'Health practitioner',
  MANAGER = 'Manager',
  REPORT_EXTRACTOR = 'Report extractor',
  DATA_AUDITOR = 'Data auditor'
}


export enum API_PATHS {
  //CREATE_USER_AUTH = '/PerigonUser/Authenticate',
  RESET_CONSULTATION = '/ConsultationDetail/Reset/',
  WELLNESSDAY_CONSULTATION = '/ConsultationDetail/WellnessDay/',
  ADHOC_WELLNESSDAY_CONSULTATION = '/ConsultationDetail/AdhocWellnessDay/',
  CORPORATE_WELLNESSDAY_CONSULTATION = '/ConsultationDetail/CorporateWellnessDay/',
  CONSULTATION_DETAIL = '/ConsultationDetail/',
  CONSULTATION_REFERRAL = '/ConsultationReferral/',
  GET_CONSULTATION_REFERRAL_LIST = '/ConsultationReferral/GetConsultationReferralList/',
  CONSULTATION_HEALTH_EDUCATION = '/ConsultationHealthEducation/',
  GET_CLINICS = '/Clinic/GetAllClinics',
  GET_APPOINTMENTS = '/ConsultationDetail/GetClinicConsultations',
  GET_APPOINTMENTS_RANGE = '/ConsultationDetail/GetClinicConsultationsRange',
  POST_COF = '/ConsultationDetail/COF',
  POST_REFERRAL = '/ConsultationDetail/Referral',
  POST_CONSENT = '/ConsultationDetail/Consent',
  POST_VACCONSENT = '/ConsultationDetail/VacConsent',
  POST_INSERT_HHS = '/ConsultationDetail/InsertUpdateHHS',
  LIST_ITEMS_BY_NAME = 'List/{name}/listItems',
  GET_FILEREVIEWRECORDS = '/ConsultationDetail/GetFileReviewRecords',
  SET_CONSULTATION_DETAIL_WORKFLOW = '/ConsultationDetail/SetConsultationDetailWorkflow',
  GET_FILEREVIEWRECORDCOUNT = '/ConsultationDetail/GetFileReviewRecordCount',
  GET_TESTREQUESTEDBYWORKFLOWID = '/TestRequested/GetTestRequestedByWorkflowId',
  GET_LIST_RANKS = '/List/SapsRanks',
  GET_LIST = '/List/',
  GET_LIST_BY_NAME = '/List/GetByName/',

  REGISTER_PATIENT = '/PatientDetail/RegisterPatient',

  CREATE_WELLNESS_DAY_EVENT_BOOKING = '/WellnessDayEvent/CreateEventBooking',
  UPDATE_WELLNESS_DAY_EVENT_BOOKING = '/WellnessDayEvent/UpdateEventBooking',
  GET_ALL_WELLNESS_DAY_EVENTS = '/WellnessDayEvent/GetAllWellnessDayEvents/',
  CREATE_ADHOC_WELLNESS_DAY_EVENT_BOOKING = '/AdhocWellnessDayEvent/CreateEventBooking',
  CREATE_CORPORATE_WELLNESS_DAY_EVENT_BOOKING = '/CorporateWellnessDayEvent/CreateEventBooking',
  UPDATE_ADHOC_WELLNESS_DAY_EVENT_BOOKING = '/AdhocWellnessDayEvent/UpdateEventBooking',
  UPDATE_CORPORATE_WELLNESS_DAY_EVENT_BOOKING = '/CorporateWellnessDayEvent/UpdateEventBooking',
  CANCEL_CORPORATE_WELLNESS_DAY_EVENT_BOOKING = '/CorporateWellnessDayEvent/CancelEventBooking',
  GET_ALL_ADHOC_WELLNESS_DAY_EVENTS = '/AdhocWellnessDayEvent/GetAllWellnessDayEvents/',
  GET_ALL_CORPORATE_WELLNESS_DAY_EVENTS = '/CorporateWellnessDayEvent/GetAllWellnessDayEvents/',

  PUT_TESTPERFORMED = '/TestPerformed/',
  POST_TEST_PERFORMED = '/TestPerformed',
  PUT_TEST_PERFORMED = '/TestPerformed',
  GET_TEST_PERFORMED_FOR_REVIEWID = '/TestPerformed/GetTestPerformedForReviewId?reviewId=',

  GET_QUESTIONNAIRES = '/Questionnaire/GetAllQuestionnaires',
  GET_QUESTIONNAIRE = '/Questionnaire/GetModelled/',
  GET_HIERARCHICAL_QUESTIONNAIRE = '/Questionnaire/GetHierarchical/',
  POST_QUESTIONNAIRE = '/Response/PostResponseModel',
  PUT_QUESTIONNAIRE = '/Response/PutResponseModel',
  GET_RESPONSE_LIST = '/Response/List/',
  GET_HIERARCHICAL_RESPONSE = '/Response/GetHierarchical/',
  GET_REVIEW = '/Review/',
  PUT_TEST_REQUESTED = '/TestRequested',
  GET_TEST_REQUESTED = '/TestRequested',
  POST_DELETE_REVIEWS = '/Review/Reset',

  GET_DOCUMENT_COF = '/COFDocument/GetByConsultationId/',
  POST_DOCUMENT_COF = '/COFDocument',
  PUT_DOCUMENT_COF = '/COFDocument/',
  CREATE_DOCUMENT_COF = '/COFDocument/Create/',

  GET_DOCUMENT_REFERRAL = '/ReferralDocument/GetByReferralId/',
  POST_DOCUMENT_REFERRAL = '/ReferralDocument',
  PUT_DOCUMENT_REFERRAL = '/ReferralDocument/',
  CREATE_DOCUMENT_REFERRAL = '/ReferralDocument/Create/',

  CLIENT_DETAILS = '/PatientDetail/List',
  GET_SCHEDULE_DETAILS = '/ConsultationDetail/GetSchedulingDetail/',

  GET_QUESTIONNAIRE_EXAM_OPTIONS = '/QuestionnaireExaminationOption/GetQuestionnaireExaminationOptions/',
  GET_ALL_EXAM_OPTIONS = '/ExaminationOptions/GetAllExaminationOptions',
  GET_ITEMS_FOR_LIST = '/ListItem/ListForListID?listId=',

  GET_PATIENT_DETAIL = '/PatientDetail/Get?id=',
  GET_PATIENT_DETAIL_FROM_CONSULTATION = '/PatientDetail/GetFromConsultation?consultationId=',
  GET_DEEP_SEARCH = '/PatientDetail/DeepSearch?id=',
  SEARCH_PATIENT = '/PatientDetail/Search',
  Benefit_Check = '/PatientDetail/BenefitCheck',

  GET_CURRENT_WORKFLOW_STATUS = '/ConsultationDetailWorkflow/GetCurrentWorkflowStatus/',
  GET_WORKFLOW_STATUS_HISTORY = '/ConsultationDetailWorkflow/GetWorkflowStatusHistory/',

  GET_ALL_CLINICIANS = '/Clinician/GetAllClinicians/',
  GET_CLINICIAN = '/Clinician/GetClinician/',
  GET_CLINICIAN_BY_KEYCLOAK_ID = '/Clinician/GetClinicianByKeycloakId/',
  GET_CLINICIAN_BY_EMAIL = '/Clinician/GetClnicianByEmail/',
  GET_EVENT_BY_SERVICE_PROVIDER = '/Clinician/GetEventByServiceProviderId/',
  GET_CLINCIAN_ASSIGNED_EVENTS = '/Clinician/GetClincianAssignedEvents/',
  UPDATE_ASSIGNED_CLINICIAN = '/Clinician/UpdateAssignedEvent/',

  ADD_CLINICIAN = '/Clinician/AddClinician',
  UPDATE_CLINIC_CLINICIAN_MAP = '/Clinician/UpdateClinicClinicianMap',


  GET_ALL_CLINICS = '/Clinic/GetAllClinics/',
  GET_CLINIC = '/Clinic/GetClinic/',
  GET_WCG = '/Review/WCG/',
  POST_WCG = '/Review/WCG',

  GET_ALL_CLINIC_CLINICIAN_MAPS = '/ClinicClinicianMap/GetAllClinicClinicianMaps/',
  GET_ALL_CLINIC_CLINICIAN_MAPS_FOR_CLINICIAN = '/ClinicClinicianMap/GetAllClinicClinicianMapsForClinician/',
  GET_ALL_CLINIC_CLINICIAN_MAPS_FOR_CLINIC = '/ClinicClinicianMap/GetAllClinicClinicianMapsForClinic/',
  GET_WELLNESS_DAY_EVENT_BOOKING = '/WellnessDayEvent/GetEventBooking/',
  GET_ADHOC_WELLNESS_DAY_EVENT_BOOKING = '/AdhocWellnessDayEvent/GetEventBooking/',
  GET_CORPORATE_WELLNESS_DAY_EVENT_BOOKING = '/CorporateWellnessDayEvent/GetEventBooking/',
  POST_WELLNESS_CONSENT = "/WellnessConsentDocument/Generate",
  POST_CORPORATE_WELLNESS_CONSENT = "/WellnessConsentDocument/GenerateCorporate",
  POST_ADHOC_WELLNESS_CONSENT = "/WellnessConsentDocument/GenerateAdhoc",
  POST_WELLNESS_HIV_CONSENT = "/WellnessHIVConsentDocument/Generate",
  POST_WELLNESS_PSA_CONSENT = "/WellnessHIVConsentDocument/Generate/PSA",
  POST_WELLNESS_FLU_CONSENT = "/WellnessHIVConsentDocument/Generate/FLU",
  POST_WELLNESS_PAPSMEAR_CONSENT = "/WellnessHIVConsentDocument/Generate/PAPSMEAR",
  GET_ALL_SERVICE_PROVIDERS = "/Clinic/GetAllServiceProviders/",
  GET_SERVICE_PROVIDER_USERS = "/Clinician/GetCliniciansByServiceProvider/",
  GET_ADMIN_ASSISTANT_USERS = "/Clinician/GetAdminAssistantsByServiceProvider/",
  GET_HEALTH_PRACTITIONER = "/Clinician/GetClinician/",
  POST_HEALTH_PRACTITIONER_ENABLE = "/HealthPractitioner/Enable",
  GET_EXECUTION_REPORT = '/ExecutionReport/Report/',
  POST_HEALTH_PRACTITIONER_RESET_PASSWORD = "/HealthPractitioner/ResetPassword/",
  GET_WDE_EVENT_SUMMARY = "/Reporting/WellnessEventSummary/",


  GET_ADHOC_WDE_EVENT_SUMMARY = "/Reporting/AdhocWellnessEventSummary/",
  GET_ALL_EVENT_NAMES = "/Reporting/GetAllEvents/",


  GET_CORPORATE_WDE_EVENT_SUMMARY = "/Reporting/CorporateWellnessEventSummaryReport/",
  POST_DOWNLOAD_WDE_EVENT_SUMMARY = "/Reporting/DownloadWellnessEventSummary/",

  SearchClaimsReport = "/Claim/SearchClaimsReport/",

  //Search Transactional
  GET_Transactional_EVENT_SUMMARY = "/Reporting/SearchTransactionalReportSummary/",

  Get_Adhoc_TransactionalReport = "/AdhocReport/SearchAdhocTransactionalReportSummary/",

  GET_CORPORATE_Transactional = "/Reporting/DownloadCorporateWellnessTransactional/",

  Download_Adhoc_TransactionalReport = "/AdhocReport/DownloadAdhocWellnessTransactional/",

  //Downloading the report
  POST_ADHOC_DOWNLOAD_WDE_EVENT_SUMMARY = "/Reporting/DownloadAdhocWellnessEventSummary/",
  POST_CORPORATE_DOWNLOAD_WDE_EVENT_SUMMARY = "/Reporting/DownloadCorporateWellnessEventSummary/",



  //EAPA Conferencing download report
  DownloadEAPAreport = '/EAPAConferencing/DownloadEAPAReportSummary',


  //EAPA CONFERENCING
  POST_EAPA_CONFERENCING_REGISTRATION = '/EAPAConferencing/EAPAConferencingRegister',

  //Claim
  DownloadClaimsReport = '/Claim/DownloadClaimReport/',


  GET_CLAIM_COMPANY = '/Claim/GetAllClaimByCompany/',
  GET_REJECTED_CLAIM_COMPANY = '/Claim/GetAllRejectedClaimByCompany/',
  INSERT_INTO_CLAIMRESPONSE = '/Claim/InsertIntoClaimResponse/',
  UPDATE_INTO_CLAIMRESPONSE = '/Claim/UpdateInClaimResponse/',
  PROCESS_CLAIM = '/Claim/ProcessClaim/',
  GET_REJECTED_CLAIM_COMPANY_BY_ID = '/Claim/GetRejectedMembers/',

  POST_HEALTH_PRACTITIONER_REGISTRATION = '/HealthPractitioner/Register',
  Get_Service_Provider_Details = '/HealthPractitioner/GetServiceProviderDetails/',
  POST_ADMIN_ASSISTANT_REGISTRATION = '/HealthPractitioner/RegisterAdminAssistant',
  GET_EVENT_ACTIVITY_HISTORY = '/WellnessDayEvent/History/',
  GET_CORPORATE_EVENT_ACTIVITY_HISTORY = '/CorporateWellnessDayEvent/History/',
  GET_ADHOC_EVENT_ACTIVITY_HISTORY = '/AdhocWellnessDayEvent/History/',
  POST_HEALTHY_HEART_SCORE = 'https://healthy-heart-score.onrender.com/generate-pdf/',

  //thirdparty api call url
  GET_HEALTHY_HEART_SCORE = "/Multiply/GetMultiplyPDF",

  //Support Calls enpoint
  GET_ASSESSMENTS_DONE = "/Support/GetAssessmentsPerformed/",
  CHANGE_CONSENT = "/Support/ChangeConsent/",
  MOVE_EVENT = "/Support/MoveEvent",


}

export enum KeyclooakEndpoints {
  //Keycloak RestfulApi's
  GET_USER_BY_EMAIL = "/Keycloak/UserByEmail",
  GET_USER_SESSION = "/Keycloak/UserSession",
  DELETE_USER_SESSION = "/Keycloak/DeleteUserSession"
}

export enum CSS_POSITION {
  ABSOLUTE = 'absolute',
  STATIC = 'static',
  RELATIVE = 'relative',
  FIXED = 'fixed',
}

export enum SERVICE_PROVIDER_USER_TYPE {
  HEALTH_PRACTITIONER = 0,
  ADMINISTRATIVE_ASSISTANT = 1
}

export enum RISK_RATING {
  NOTICE = -1,
  LOW = 0,
  HIGH = 1,
  VERY_HIGH = 2,
}

export enum WELLNESS_EVENT_TYPE {
  SAPS = 1,
  ADHOC = 2,
  CORPORATE = 3
}

export enum STORAGE_VARS { }

export enum LEVELS {
  DEBUG,
  INFO,
  WARNING,
  ERROR,
}

export enum WELLNESSCONSENTSTATUS {
  Global = 1 << 0, // 1
  TBStatus = 1 << 1, // 2
  TBQuestionnaire = 1 << 2, // 4
  HIV = 1 << 3, // 8
  Psychosocial = 1 << 4, // 16
  InformationSharedToHRM = 1 << 5, // 32
  InformationToThirdParties = 1 << 6, // 64
  ReceiveSMS = 1 << 7, // 128
  InformationSharedToEmployer = 1 << 8, // 256
}

export enum CORPWELLNESSCONSENTSTATUS {
  HRA = 1 << 0, // 1
  TB = 1 << 1, // 2
  HIV = 1 << 2, // 4
  PSA = 1 << 3, // 8
  FLU = 1 << 4, // 16
  STRESS = 1 << 5, // 32
  MENTALWELLBEING = 1 << 6, // 64
  BREAST = 1 << 7,
  //BIOMETRIC = 1 << 7, // 128
  PAPSMEAR = 1 << 8, // 256
  INDEMNITY = 1 << 9 // 128,
  // 256
}

export enum ADHOCWELLNESSCONSENTSTATUS {
  // HRA = 1 << 0, // 1
  // TB = 1 << 1, // 2
  // HIV = 1 << 2, // 4
  // PSA = 1 << 3, // 8
  // FLU = 1 << 4, // 16
  // STRESS = 1 << 5, // 32
  // MENTALWELLBEING = 1 << 6, // 64
  COMPACTHRA = 1 << 7, // 1024
  SNELLEN = 1 << 8, // 2048
  //BIOMETRIC = 1 << 9, // 128
  //BENEFIT = 1 << 10, // 256
  INDEMNITY = 1 << 11, // 512

}

export enum ENVIRONMENT_VARS {
  GOOGLE_ANALYTICS_CODE = 'UA-xxxxxxxx-x',
  CAPTURE_PRcOCESS_URL = '/newapplication/capture_screens',
}

export enum QUESTION_TYPE {
  Selection = 0,
  MultipleSelection = 1,
  Toggle = 2,
  Text = 3,
  MultilineText = 4,
  Range = 5,
  Numeric = 6,
  Decimal = 7,
  Currency = 8,
  Date = 9,
  DateTime = 10,
  Time = 11,
  Image = 12,
  Signature = 13,
  ToggleAndComment = 14,
  NumericAndComment = 15,
  Expression = 16,
  SelectionAndComment = 17,
  CommentAndYear = 18,
  ServerRequest = 19,
  MultipleSelectionAndComment = 20,
  GroupLabel = 21,
  Button = 22,
  Grid = 23,
  MultipleSelectionWithSubQuestion = 24,
  GroupStart = 25,
  GroupEnd = 26,
  Slider = 27,
  Radio = 28,
}

export class GLOBAL_VARS {
  public static GROUP_NAMES: string[] = [
    'consent',
    'administration',
    'audiometry',
    'cardiovascular tests',
    'diving',
    'pathology',
    'physiotherapy',
    'questionnaires',
    'radiology',
    'spirometry evaluation',
    'vaccination / prophylaxis',
    'vision screening',
    'vitals and rapid tests',
    'nurse examination',
  ]

  public static CORRECT_GROUP_NAMES: string[] = [
    'Consent',
    'Administration',
    'Audiometry',
    'Cardiovascular Tests',
    'Diving',
    'Pathology',
    'Physiotherapy',
    'Questionnaires',
    'Radiology',
    'Spirometry evaluation',
    'Vaccination / Prophylaxis',
    'Vision Screening',
    'Vitals and rapid tests',
    'Nurse Examination',
  ]
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
}

export enum EVENT_ACTIVITY_TYPE {
  CREATE,
  MODIFY,
  PLANNED,
  EXECUTED,
  CANCELLED
}

