import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { $ } from 'protractor';
import { SupportService } from 'src/app/shared/services/support.service';
import { WellnessReportService } from 'src/app/shared/services/wellness-report.service';
import { millenialIdNumberValidtor } from 'src/app/shared/validators/millenial-id-number.validator';
import Swal from 'sweetalert2';

import * as Enums from '../../../shared/enums';

@Component({
  selector: 'app-change-consent',
  templateUrl: './change-consent.component.html',
  styleUrls: ['./change-consent.component.scss']
})
export class ChangeConsentComponent implements OnInit {

  isLoading = false;
  signupForm!: FormGroup;
  companyNames: any;
  selectedCompanyNames: any;
  assessmentsData: any = [];
  enableConsent: boolean = false;
  displayedColumns: string[] = ['Tests'];
  consentColumns: string[] = ['Add', 'Assessment'];
  consentOptions = [
    { id: Enums.CORPWELLNESSCONSENTSTATUS.HRA, name: 'Personal health risk assessment' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.TB, name: 'TB Screening Questionnaire' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.HIV, name: 'Confidential and voluntary HIV test' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.PSA, name: 'PSA' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.FLU, name: 'Flu Vaccine Test' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.STRESS, name: 'Stress questionnaire' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.MENTALWELLBEING, name: 'Mental Wellbeing questionnaire' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.BREAST, name: 'Breast Screening' },
    { id: Enums.CORPWELLNESSCONSENTSTATUS.PAPSMEAR, name: 'Pap smear screening' }
  ];
  dataSource = new MatTableDataSource<any>(this.assessmentsData);

  selectedConsents: number[] = [];
  consentStatusValue: number;

  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private eventNames: WellnessReportService, private supportService: SupportService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.isLoading = true;
    this.eventNames.getEventNames().subscribe((data) => {
      this.companyNames = data.sort((a, b) => a.description.localeCompare(b.description));
      this.isLoading = false;
    });
  }

  goToLanding() {
    this.router.navigate(['../wellness-corporate/landing'], { relativeTo: this.route });
  }

  createForm() {
    this.signupForm = this.fb.group({
      companyId: [null, Validators.required],
      idNumber: ['', [Validators.required]],
    })
  }

  selectOption(event: any) {
    console.log(event.value);
    this.selectedCompanyNames = event.value;
  }

  async showAssessments() {
    if (this.signupForm.valid) {
      this.isLoading = true;
      const idNumber = this.signupForm.get('idNumber').value;
      const eventId = this.selectedCompanyNames;

      const data = await this.supportService.getAssessmentsPerformed(idNumber, eventId).then((data) => {
        console.log('Assessments data:', data);
        let obj = [];
        if (data != undefined) {
          this.assessmentsData = data;
          let showConsent = data.data?.filter(x =>
            ["62081507-9dd1-450e-8193-50d166c6f324", "65e10d68-d8d5-4962-a546-a561aaccbe75"].includes(x.testMappingId)).length > 0;
          if (showConsent) {
            this.enableConsent = true;
          }
          obj = data.data;
          //this.dataSource = new MatTableDataSource(data.data);
        }
        else {
          this.enableConsent = false;
        }
        this.dataSource = new MatTableDataSource(obj);
        this.isLoading = false;
      }, (error) => {
        console.error('Error fetching assessments:', error);
        this.isLoading = false;
      });
    }
  }

  onConsentChange(consentValue: number, checked: boolean) {
    if (checked) {
      this.selectedConsents.push(consentValue);
    } else {
      const index = this.selectedConsents.indexOf(consentValue);
      if (index > -1) {
        this.selectedConsents.splice(index, 1);
      }
    }

    // Calculate total including indemnity
    const indemnityConcent = Enums.CORPWELLNESSCONSENTSTATUS.INDEMNITY;
    const totalValue = this.selectedConsents.reduce((sum, current) => sum + current, 0) + indemnityConcent;
    this.consentStatusValue = totalValue;

    // console.log('Selected consents:', this.selectedConsents);
    // console.log('Total consent value (including indemnity):', totalValue);
  }

  async modifyAssessments() {
    const selectedConsentNames = this.selectedConsents.map(consentValue =>
      this.consentOptions.find(option => option.id === consentValue)?.name
    ).filter(name => name);
    if (selectedConsentNames.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'No assessments selected',
        text: 'Please select at least one assessment to add to your consultation.',
        confirmButtonText: 'Ok'
      });
      return;
    }
    this.isLoading = true;
    await this.supportService.changeConsent(
      this.signupForm.get('idNumber').value,
      this.selectedCompanyNames,
      this.consentStatusValue.toString()
    ).then(response => {
      console.log('Consent modified successfully:', response);
      this.isLoading = false;
      Swal.fire({
        icon: 'success',
        title: 'Assessments on consultation added',
        html: `The following assessments have been added to your consultation:<br><ul>${selectedConsentNames.map(name => `<li>${name}</li>`).join('')}</ul>`,
        confirmButtonText: 'Ok'
      });

      //this.goToLanding();
    }, error => {
      console.error('Error modifying consent:', error);
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error adding assessments to consultation',
        text: `${error.message}`,
        confirmButtonText: 'Ok'
      });
    })
    // else {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: 'No Consents Selected',
    //     text: 'Please select at least one consent option before proceeding',
    //     confirmButtonText: 'Ok'
    //   });
    // }
  }


}
