import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-claim-landing',
  templateUrl: './claim-landing.component.html',
  styleUrls: ['./claim-landing.component.scss']
})
export class ClaimLandingComponent implements OnInit {

  isLoading = false;
  isFailed = false;

  constructor(private router: Router, private route: ActivatedRoute, private globalData: GlobalDataService) {
    this.globalData.updateBackRoute('/wellness-event/corporate/financial');
  }

  ngOnInit(): void {
  }

  goToClaimByCompany() {

    this.router.navigate(['../claim-by-company'], { relativeTo: this.route });
  }

  goToFixClaimByCompany() {

    this.router.navigate(['../fix-claim'], { relativeTo: this.route });
  }

  Report() {
    this.router.navigate(['/wellness-event/corporate/financial/claims-report']);
  }



}
