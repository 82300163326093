<div class="container">
  <article class="grid">
    <div class="grid__inner grid__inner--layout-b8b">
      <div class="grid__cell">

        <div class="container">
          <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
          <three-bar-loader *ngIf="isLoading"></three-bar-loader>
        </div>

        <div class="container">
          <mat-tab-group>
            <mat-tab label="Health practitioners">
              <ng-template matTabContent>
                <div class="container content-container" style="position: relative">
                  <button class="mat-fab add-button" (click)="addHealthPractitioner()">
                    <span class="mat-button-wrapper">
                      <i class="material-icons" role="img">add</i>
                    </span>
                  </button>
                  <h2 class="mdc-typography--headline4">Health practitioners</h2>

                  <!--<mat-form-field class="example-full-width">
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                </mat-form-field>-->

                  <mat-table [dataSource]="practitioners" class="mat-elevation-z8" style="margin-top: 30px;">
                    <!-- Other columns as needed -->
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner"> {{practitioner.name}} </mat-cell>
                    </ng-container>
                    <!-- Other columns as needed -->
                    <ng-container matColumnDef="active">
                      <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner"> {{practitioner.active}} </mat-cell>
                    </ng-container>
                    <!-- Other columns as needed -->
                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner"> {{practitioner.email}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner">
                        <div *ngIf="!practitioner.active">
                          <button mat-button (click)="onActivate(practitioner)">Activate</button>
                        </div>
                        <div *ngIf="practitioner.active">
                          <button mat-button (click)="onDeactivate(practitioner)">Deactivate</button>
                        </div>
                        <div>
                          <button mat-button (click)="onResetPassword(practitioner.email)">Reset Password</button>
                        </div>
                        <div>
                          <button mat-button (click)="assignEventPractitioner(practitioner)">Assign to Events</button>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </ng-template>
            </mat-tab>
            <mat-tab label="Admin assistants">
              <ng-template matTabContent>
                <div class="container content-container" style="position: relative">
                  <button class="mat-fab add-button" (click)="addAdminAssistant()">
                    <span class="mat-button-wrapper">
                      <i class="material-icons" role="img">add</i>
                    </span>
                  </button>
                  <h2 class="mdc-typography--headline4">Admin assistants</h2>
                  <!--<mat-form-field class="example-full-width">
                  <input matInput (keyup)="applyAAFilter($event.target.value)" placeholder="Filter">
                </mat-form-field>-->

                  <mat-table [dataSource]="assistants" class="mat-elevation-z8" style="margin-top: 30px;">
                    <!-- Other columns as needed -->
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner"> {{practitioner.name}} </mat-cell>
                    </ng-container>
                    <!-- Other columns as needed -->
                    <ng-container matColumnDef="active">
                      <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner"> {{practitioner.active}} </mat-cell>
                    </ng-container>
                    <!-- Other columns as needed -->
                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner"> {{practitioner.email}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                      <mat-cell *matCellDef="let practitioner">
                        <div *ngIf="!practitioner.active">
                          <button mat-button (click)="onActivate(practitioner)">Activate</button>
                        </div>
                        <div *ngIf="practitioner.active">
                          <button mat-button (click)="onDeactivate(practitioner)">Deactivate</button>
                        </div>
                        <div>
                          <button mat-button (click)="onResetPassword(practitioner.email)">Reset Password</button>
                        </div>
                        <div>
                          <button mat-button (click)="assignEventAssistant(practitioner)">Assign to Events</button>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </article>
</div>