import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import * as Enums from 'src/app/shared/enums'
import { ClaimParams } from 'src/app/shared/interfaces/claimParams'
import { RegisterPatientSearchParameters, WellnessPatientRegistrationModel } from 'src/app/shared/interfaces/patientMapper'
import { ListItemObject } from 'src/app/shared/interfaces/questionnaireMapper'
import { SouthAfricanId } from 'src/app/shared/interfaces/south-african-id'
import { ClaimsService } from 'src/app/shared/services/claims.service'
import { ConsultationService } from 'src/app/shared/services/consultation.service'
import { GlobalDataService } from 'src/app/shared/services/global-data.service'
import { ListService } from 'src/app/shared/services/list.service'
import { PatientService } from 'src/app/shared/services/patient.service'
import { cellNumberValidator } from 'src/app/shared/validators/cell-number.validator'
import { emailAddressValidator } from 'src/app/shared/validators/email-address.validator'
import { passportNumberValidator } from 'src/app/shared/validators/passport.validator'
import { southAfricanIdNumberValidator } from 'src/app/shared/validators/south-african-id-number.validator'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-corporate-wellness-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class CorporateWellnessRegistrationComponent implements OnInit, AfterViewChecked {

  // Element Refs
  @ViewChild('saveButton', { static: false, read: ElementRef }) saveButtonRef!: ElementRef;


  // Forms
  registrationForm: FormGroup;
  searchForm: FormGroup;

  // Lookups
  genders: ListItemObject[];
  maritalStatuses: ListItemObject[];
  languages: ListItemObject[];
  medicalAids: ListItemObject[];
  radioMedicalAids: ListItemObject[];
  dropdownMedicalAids: ListItemObject[];
  countries: ListItemObject[];

  // Loader Switches
  gendersLoaded: boolean = false;
  medicalAidsLoaded: boolean = false;
  maritalStatussesLoaded: boolean = false;
  languagesLoaded: boolean = false;
  searching: boolean = false;
  saving: boolean = false;
  consulting: boolean = false;

  // Model
  registrationData: WellnessPatientRegistrationModel = null;

  // Booking arguments
  bookingEventId: string = null;
  bookingEventTitle: string = null;
  saved: boolean = false;

  maxDate = new Date();
  startDate = new Date(new Date().getFullYear() - 20, 0, 1);
  passportSearchForm: any

  // benefit-check-api: getting med aid no and name (using scheme code)
  scheme: string = null
  memberNumber: string = null;
  schemeName: string = null;
  schemeId: string = null;
  bfCheck: boolean = false;
  benefitCheckData: any;

  //claims
  claimParams: ClaimParams;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private listService: ListService,
    private patientService: PatientService,
    private claimsService: ClaimsService,
    private router: Router,
    private globalServ: GlobalDataService,
    private consultationService: ConsultationService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    //private healthyHeartScoreService: HealthyHeartScoreService
  ) {
    this.globalServ.userIdVal.subscribe(id => this.globalServ.updateBackRoute(['/wellness-event/corporate/landing']));
    this.activatedRoute.paramMap.subscribe((params) => {
      this.bookingEventId = params.get('eventId');
      this.bookingEventTitle = params.get('eventTitle');
    });

    this.searchForm = this.formBuilder.group({
      searchType: [null, Validators.required],
      searchId: ['', Validators.compose([southAfricanIdNumberValidator])],
      country: [null],
      dob: [null, Validators.required],
      passport: [null, Validators.compose([passportNumberValidator])]
    });

    this.registrationForm = this.formBuilder.group({
      fullNames: ['', Validators.required],
      surname: ['', Validators.required],
      idNo: ['', null],
      persalNumber: ['', Validators.required],
      genderId: ['', Validators.required],
      languageId: ['', Validators.required],
      medicalAidId: ['', Validators.required],
      medicalAidNumber: ['', null],
      email: ['', emailAddressValidator],
      cellphone: ['', Validators.compose([Validators.required, cellNumberValidator])],
      maritalStatusId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.populateLookups()
  }

  ngAfterViewChecked(): void {
    const secondFormExists = document.getElementById('registrationForm') !== null;
    if (secondFormExists && this.saved) {
      this.saved = false;
      this.saveButtonRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  restrictInput(event: any) {
    const pattern = /[\.\+\#]/; // regular expression
    let inputChar = String.fromCharCode(event.charCode);

    if (pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  clinicianId: string;
  createConsultation(): void {
    this.consulting = true;

    this.globalServ.userIdVal.subscribe(val => { this.clinicianId = val })
    this.consultationService.getCorporateWellnessDayConsultation(this.bookingEventId, this.registrationData.id, this.clinicianId).subscribe(res => {
      this.globalServ.updateSelectedClient(res.consultationId);
      this.globalServ.updateBookingEventTitle(this.bookingEventTitle);
      this.router.navigate(['/wellness-event/corporate/consultation']);
    })
  }

  get searchFormValid() {
    return (this.searchForm.get('searchType').value === 'passport' && this.searchForm.get('passport').value && this.searchForm.get('country').value) && this.searchForm.get('dob').value ||
      (this.searchForm.get('searchType').value === 'id' && this.searchForm.get('searchId').valid && this.searchForm.get('searchId').value);
  }
  get searchFormInvalid() {
    return !this.searchFormValid;
  }

  get consultationDisabled() {
    return this.registrationForm.invalid || this.registrationForm.dirty || !this.bfCheck
  }

  medAidName() {
    // this.listService.get(Enums.LISTS.MEDICAL_AIDS).subscribe(data => {
    //   if (this.scheme) {
    //     this.schemeName = data.listItems.find(x => x.code == this.scheme).description;
    //     this.schemeId = data.listItems.find(x => x.code == this.scheme).id;

    //   }
    // })
    if (this.scheme) {
      if (this.medicalAidsLoaded) {
        this.schemeName = this.medicalAids.find(x => x.code == this.scheme).description;
        this.schemeId = this.medicalAids.find(x => x.code == this.scheme).id;
      }
    }

  }

  // getHealthyHeartScore() {
  //   this.healthyHeartScoreService.getMultiplyPDF("").subscribe(data => {
  //     debugger;
  //     const blob = base64ToBlob(data.stringData, 'application/octet-stream');
  //     // Create a Blob URL for the PDF
  //     const blobUrl = window.URL.createObjectURL(blob);

  //     // Open the PDF in a new tab
  //     //window.open(blobUrl, '_blank');


  //     // Create a temporary anchor element to trigger the download
  //     const link = document.createElement('a');
  //     link.href = blobUrl;
  //     link.download = 'healthy_heart_score.pdf';
  //     link.click();

  //     // Clean up the Blob URL
  //     window.URL.revokeObjectURL(blobUrl);
  //     console.log("data");
  //   },
  //     error => {
  //       console.log("error while fetching data", error);
  //     })
  // }

  search() {
    if (this.searchFormValid) {
      this.searching = true
      let params: RegisterPatientSearchParameters = {
        searchType: this.searchForm.get('searchType').value,
      }
      let dob_param;
      if (params.searchType === 'id') {
        params.idNumber = this.searchForm.get('searchId').value

      } else if (params.searchType === 'passport') {
        params.passport = this.searchForm.get('passport').value
        params.countryId = this.searchForm.get('country').value
        params.dob = this.searchForm.get('dob').value
      }
      if (params.idNumber || params.passport) {
        if (params.idNumber) {
          let id = params.idNumber;
          console.log("DOB when using passport", params.dob);
          let id_day = id.substring(4, 6) || params.dob.getDay();
          let id_month = id.substring(2, 4) || params.dob.getMonth();
          let id_year = (id.substring(0, 2) > "00" ? "19" + id.substring(0, 2) : "20" + id.substring(0, 2)) || params.dob.getFullYear();
          // let dob = new Date(`${id_year}-${id_month}-${id_day}`)
          dob_param = id_year + '-' + id_month + '-' + id_day;
          console.log(`Id no ${id} and dob ${dob_param}`)
        }
        else if (params.passport) {
          let id_day = params.dob.getDate() < 10 ? '0' + params.dob.getDate() : params.dob.getDate();
          let month = params.dob.getMonth() + 1;
          let id_month = month < 10 ? '0' + month : month.toString();
          let id_year = params.dob.getFullYear();
          dob_param = id_year + '-' + id_month + '-' + id_day;
        }
        params.dob = dob_param;

      }

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      })
      let id = params.idNumber || params.passport;

      this.patientService.search(params).subscribe((data) => {
        let mes = data.mes?.split(',');
        data = data.response as WellnessPatientRegistrationModel
        data = data || {
          id: '00000000-0000-0000-0000-000000000000',
          fullNames: null,
          surname: null,
          persalNumber: null,
          genderId: params.searchType == 'id' ? new SouthAfricanId(this.searchForm.get('searchId').value).genderId : null,
          rankId: null,
          idNo: params.searchType == 'id' ? this.searchForm.get('searchId').value : null,
          passportNo: params.searchType == 'passport' ? this.searchForm.get('passport').value : null,
          countryId: params.searchType == 'passport' ? this.searchForm.get('country').value : null,
          dob: params.searchType == 'passport' ? this.searchForm.get('dob').value : null,
          languageId: null,
          medicalAidId: this.schemeId,
          medicalAidNumber: this.memberNumber,
          email: null,
          cellphone: null,
          maritalStatusId: null,
        }

        this.registrationData = data;

        this.registrationForm.markAsPristine()



        if (mes[0] == 'Success') {
          Swal.fire({
            title: `Benefit Status: ${mes[0]}`,
            text: mes[1],
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }
        else if (mes[0] == 'Failure') {
          Swal.fire({
            title: `Benefit Status: ${mes[0]}`,
            text: mes[1],
            icon: 'warning',
            confirmButtonText: 'Ok'
          })
        }
        else {
          Swal.fire({
            title: `Patient Benefit Check - Error`,
            text: mes[0],
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        this.bfCheck = true;
        this.searching = false;

        // this.http.get(`https://mediscor-benefit-api.onrender.com/check?DateOfBirth=${dob_param}&IDNumber=${id}`, { headers }).subscribe(
        //   (res) => {
        //     this.benefitCheckData = res; // Insert into benefitcheck table

        //     //Benefit Check Rules:
        //     /*
        //     1st) MDS0041 - Momentum Health ('The Scheme') - R157.50
        //     2nd) MDS0042 - Health4Me -R157.50
        //     3rd) MDS0056 - Multiply & Camaf - R188.00
        //     */
        //     let benefitInsertObject = {
        //       BenefitStatus: "",
        //       BenefitMessage: "",
        //       SchemeCode: "",
        //       MemberNumber: "",
        //       DependentCode: "",
        //       DateOfBirth: ""
        //     };

        //     // this.benefitCheckData.forEach(obj => {

        //     //   if (obj["BenefitStatus"] == "Success") {
        //     //     if (obj["SchemeCode"] == "MDS0056") {
        //     //       benefitInsertObject = obj;
        //     //       this.claimsService.parameter.SubmittedCost = "000015980";
        //     //       this.claimsService.parameter.TotalVAT = "000002820";
        //     //       this.claimsService.parameter.GrossAmountDue = "000018800"
        //     //     }
        //     //   }
        //     //   else
        //     //     benefitInsertObject = obj;
        //     // });

        //     let b;

        //     b = this.benefitCheckData.filter(x => x?.BenefitStatus == "Success" && x?.SchemeCode == "MDS0056")[0];
        //     // Also modify NDCCode in claims table
        //     if (b != undefined) {
        //       this.claimsService.parameter.SubmittedCost = "000015980";
        //       this.claimsService.parameter.TotalVAT = "000002820";
        //       this.claimsService.parameter.GrossAmountDue = "000018800";
        //     }
        //     if (b == undefined) {
        //       b = this.benefitCheckData.filter(x => x?.BenefitStatus == "Success")[0];
        //     }
        //     if (b == undefined) {
        //       b = this.benefitCheckData.filter(x => x?.BenefitStatus == "Failure")[0]
        //     }
        //     benefitInsertObject = b;

        //     // Displaying Benefit Check Message to User
        //     if (benefitInsertObject.BenefitStatus == 'Success') {

        //       this.scheme = benefitInsertObject.SchemeCode;
        //       this.memberNumber = benefitInsertObject.MemberNumber;
        //       this.medAidName();
        //       this.registrationData.medicalAidId = this.schemeId;
        //       this.registrationData.medicalAidNumber = this.memberNumber;

        //       //this.ClaimDataInitilization(data[0]);

        //       //console.log("Registration data ", this.registrationData);

        //       console.log(`Shceme: ${this.scheme} and Member Number: ${this.memberNumber}`);
        //       Swal.fire({
        //         title: `Benefit Status: ${benefitInsertObject.BenefitStatus}`,
        //         text: `${benefitInsertObject.BenefitMessage}`,
        //         icon: 'success',
        //         confirmButtonText: 'OK',
        //       })
        //       //this.searching = false;
        //       this.bfCheck = true;
        //     } else if (benefitInsertObject.BenefitStatus == 'Failure') {
        //       Swal.fire({
        //         title: `Benefit Status: ${benefitInsertObject.BenefitStatus}`,
        //         text: `${benefitInsertObject.BenefitMessage}`,
        //         icon: 'warning',
        //         confirmButtonText: 'OK',
        //       })
        //       this.searching = false;
        //       this.bfCheck = true;
        //     }
        //     console.log('Benefit Check Data', data)

        //     let benefitInserted = {
        //       patientDetailId: this.registrationData.id,
        //       rsaidnumber: this.registrationData.idNo,
        //       passportNo: this.registrationData.passportNo,
        //       benefitStatus: benefitInsertObject.BenefitStatus,
        //       benefitMessage: benefitInsertObject.BenefitMessage,
        //       schemeCode: benefitInsertObject.SchemeCode,
        //       memberNumber: benefitInsertObject.MemberNumber,
        //       dependentCode: benefitInsertObject.DependentCode,
        //       dateOfBirth: benefitInsertObject.DateOfBirth,
        //       jsonResponse: JSON.stringify(this.benefitCheckData)
        //     }
        //     this.patientService.insertInBenfitCheck(benefitInserted).subscribe(res => {
        //       console.log("data is successfully save in benefit check", res);
        //       this.searching = false;
        //     },
        //       (error) => {
        //         console.log("Error while calling benefit check api call", error)
        //         this.searching = false;
        //       })

        //   },
        //   (error) => {
        //     Swal.fire({
        //       title: 'Patient Benefit Check - Error',
        //       text: `${error.message}`,
        //       icon: 'error',
        //       confirmButtonText: 'OK'
        //     });
        //     //this.searching = false;
        //     console.log('Benefit Check Error', error)
        //     this.bfCheck = true;
        //     this.searching = false;
        //   }
        // )
      })

    } else {
      this.searchForm.updateValueAndValidity()
    }
  }

  save() {
    if (this.registrationForm.valid) {
      this.saving = true;
      this.registrationData.fullNames = this.registrationData.fullNames.trim().replace(/\s+/, " ");
      this.registrationData.surname = this.registrationData.surname.trim().replace(/\s+/, " ");
      this.registrationData.persalNumber = this.registrationData.persalNumber.trim().replace(/\s+/, " ");
      //this.registrationData.
      this.patientService.updateWellnessPatient(this.registrationData).subscribe({
        next: (data) => {
          this.saved = true;
          this.saving = false;
          this.registrationData.id = data;
          this.registrationForm.markAsPristine();
          //this.saveButtonRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
          this.snackBar.open('Patient details saved', null, { duration: 2500, panelClass: 'app-notification-success' });
        },
        error: (e) => {
          this.snackBar.open('Submission Error', null, { duration: 2500, panelClass: 'app-notification-error' });
        }
      });
    }
    else {
      this.registrationForm.updateValueAndValidity();
    }
  }

  onInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  // markFormGroupTouched(formGroup: FormGroup) {
  //   Object.values(formGroup.controls).forEach((control) => {
  //     control.markAsTouched()

  //     if (control instanceof FormGroup) {
  //       this.markFormGroupTouched(control)
  //     }
  //   })
  // }

  isControlInvalid(controlName: string): boolean {
    const control = this.registrationForm.get(controlName) || this.searchForm.get(controlName);
    return control.invalid && control.touched;
  }

  populateLookups(): void {
    this.listService.get(Enums.LISTS.COUNTRIES).subscribe((data) => {
      this.countries = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
    });
    this.listService.get(Enums.LISTS.GENDERS).subscribe((data) => {
      this.genders = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.gendersLoaded = true
    })
    this.listService.get(Enums.LISTS.MEDICAL_AIDS).subscribe((data) => {
      this.medicalAids = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.radioMedicalAids = data.listItems.filter(x => x.code.toLowerCase() == 'radio')
      this.dropdownMedicalAids = data.listItems.filter(x => x.code.toLowerCase() !== 'radio')
      this.medicalAidsLoaded = true;
      //console.log("Medical Aid ", this.medicalAids);
    })
    this.listService.get(Enums.LISTS.MARITAL_STATUSES).subscribe((data) => {
      this.maritalStatuses = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.maritalStatussesLoaded = true
    })
    this.listService.get(Enums.LISTS.LANGUAGES).subscribe((data) => {
      this.languages = data.listItems.sort((a, b) => a.description.localeCompare(b.description));
      this.languagesLoaded = true
    })
  }

  private ClaimDataInitilization(apiData) {
    this.claimsService.parameter.MediscorPCNCode = this.scheme + 'PW';
    this.claimsService.parameter.MemberNumber = this.memberNumber;
    this.claimsService.parameter.DependentCode = apiData.dependantCode;

    this.claimsService.parameter.PatientFirstName = this.registrationData.fullNames;
    this.claimsService.parameter.PatientSurname = this.registrationData.surname;
    this.claimsService.parameter.PatientRefNumber = this.registrationData.idNo || this.registrationData.passportNo;
    let DOB_String = this.globalServ.formatDate(this.registrationData.dob);
    this.claimsService.parameter.DateOfBirth = DOB_String;

    let genderCode = this.genders.filter(x => x.id == this.registrationData.genderId)[0].code;
    this.claimsService.parameter.PatientGender = genderCode;




  }

}
// Function to convert a base64 encoded string to a Blob
function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}