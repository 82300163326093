import cli from '@angular/cli';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SERVICE_PROVIDER_USER_TYPE } from 'src/app/shared/enums';
import { AdminAssistantUser, ServiceProviderUser } from 'src/app/shared/interfaces/service-provider-user';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { ServiceProviderService } from 'src/app/shared/services/service-provider.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assign-events',
  templateUrl: './assign-events.component.html',
  styleUrls: ['./assign-events.component.scss']
})


export class AssignEventsComponent implements OnInit {
  @Input() practioner: ServiceProviderUser;
  @Input() assistant: AdminAssistantUser;
  @ViewChild('assignedPaginator') assignedPaginator: MatPaginator;
  @ViewChild('unassignedPaginator') unassignedPaginator: MatPaginator;
  @ViewChild('eventsPaginator') eventsPaginator: MatPaginator;

  isLoading = false;
  radioButtonValue: any;
  isAssignedEvents: boolean = true;
  clincianId: string;
  eventsData: any = [];
  assignedEvents: any = [];
  unassignedEvents: any = [];
  selectedEvents: string[] = [];
  showAssignButton: boolean = false;
  assignEventsColumn: string[] = ['Event', 'Date'];
  allServiceProviderEventsColumn: string[] = ['Select', 'Event', 'Date'];
  assignedEventData = new MatTableDataSource<any>(this.assignedEvents);
  unassignedEventData = new MatTableDataSource<any>(this.unassignedEvents);
  serviceProviderEventData = new MatTableDataSource<any>(this.eventsData);
  assignedEventsPageSize = 0;
  unasssignedEventsPageSize = 0;
  serviceProviderEventsPageSize = 0;
  showServiceProviderPaginator: boolean = false;
  deselectedEvents: string[] = [];
  assignEventData: any;
  constructor(private serviceProviderService: ServiceProviderService, private router: Router, private globalData: GlobalDataService) {
    this.globalData.updateBackRoute('/sp-access-management');
  }

  private getAssignedEventIds(): string[] {
    if (!this.assignedEvents?.data) {
      return [];
    }
    return this.assignedEvents.data.map(event => event.id);
  }




  async ngOnInit(): Promise<void> {
    this.radioButtonValue = 'assign';
    this.selectedEvents = [];
    if (history?.state) {
      if (history.state['userType'] === 'practitioner') {
        this.practioner = history.state['practioner'];
        // Use practitioner logic
        await this.loadPractitionerEvents();
      } else if (history.state['userType'] === 'assistant') {
        this.assistant = history.state['assistant'];
        // Use assistant logic
        await this.loadAssistantEvents();
      }
    }

    let ClincianData = JSON.parse(localStorage?.getItem('Clinician'));
    this.clincianId = ClincianData.id;
    this.isLoading = true;
    await this.LoadGrid();
  }

  private async loadPractitionerEvents(): Promise<void> {
    this.isLoading = true;
    await this.serviceProviderService.getClincianEvents(this.practioner.id).then(data => {
      this.selectedEvents = data?.assignedEvent?.map(event => event.id);
      this.assignedEvents = new MatTableDataSource(data?.assignedEvent);
      this.unassignedEvents = new MatTableDataSource(data?.unassignedEvent);
      if (data?.assignedEvent?.length > 0) {
        this.assignedEvents.paginator = this.assignedPaginator;
      }
      if (data?.unassignedEvent?.length > 0) {
        this.unassignedEvents.paginator = this.unassignedPaginator;
      }
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
    });
  }

  private async loadServiceProviderEvents(clinicianloggedId: string, practionerId: string): Promise<void> {
    await this.serviceProviderService.getEventByServiceProvider(clinicianloggedId, practionerId).then(data => {
      this.eventsData = new MatTableDataSource(data);
      this.serviceProviderEventsPageSize = data?.length;
      if (data?.length > 0) {
        this.eventsData.paginator = this.eventsPaginator;
      }
      this.isLoading = false;
    }).catch(err => {
      console.error('Error fetching events for service provider:', err);
      this.isLoading = false;
    });
  }

  private async loadAssistantEvents(): Promise<void> {
    this.isLoading = true;
    await this.serviceProviderService.getClincianEvents(this.assistant.id).then(data => {
      this.selectedEvents = data?.assignedEvent ? data.assignedEvent.map(event => event.id) : [];
      this.assignedEvents = new MatTableDataSource(data?.assignedEvent || []);
      this.unassignedEvents = new MatTableDataSource(data?.unassignedEvent);
      if (data?.assignedEvent?.length > 0) {
        this.assignedEvents.paginator = this.assignedPaginator;
      }
      if (data?.unassignedEvent?.length > 0) {
        this.unassignedEvents.paginator = this.unassignedPaginator;
      }
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
    });
  }

  async LoadGrid() {
    if (this.practioner) {
      await this.serviceProviderService.getClincianEvents(this.practioner.id).then(data => {
        this.selectedEvents = data?.assignedEvent?.map(event => event.id);
        this.assignedEvents = new MatTableDataSource(data?.assignedEvent);
        this.unassignedEvents = new MatTableDataSource(data?.unassignedEvent);
        if (data?.assignedEvent?.length > 0) {
          this.assignedEvents.paginator = this.assignedPaginator;
        }
        if (data?.unassignedEvent?.length > 0) {
          this.unassignedEvents.paginator = this.unassignedPaginator;
        }
        this.isLoading = false;
      }).catch(err => {
        console.error('Error assigned and unnassigned e:', err);
        this.isLoading = false;
      });
      if (this.clincianId) {
        await this.loadServiceProviderEvents(this.clincianId, this.practioner.id);
      }
    } else if (this.assistant) {
      await this.serviceProviderService.getClincianEvents(this.assistant.id).then(data => {
        this.selectedEvents = data?.assignedEvent?.map(event => event.id);
        this.assignedEvents = new MatTableDataSource(data?.assignedEvent);
        this.unassignedEvents = new MatTableDataSource(data?.unassignedEvent);
        if (data?.assignedEvent?.length > 0) {
          this.assignedEvents.paginator = this.assignedPaginator;
        }
        if (data?.unassignedEvent?.length > 0) {
          this.unassignedEvents.paginator = this.unassignedPaginator;
        }
        this.isLoading = false;
      }).catch(err => {
        console.error('Error assigned and unnassigned e:', err);
        this.isLoading = false;
      });
    }
    if (this.clincianId) {
      await this.loadServiceProviderEvents(this.clincianId, this.assistant.id);
    }
  }

  onEventSelect(eventId: string, checked: boolean) {
    if (!this.selectedEvents) {
      this.selectedEvents = [];
    }
    if (checked) {
      // Add to selected events
      this.selectedEvents.push(eventId);
      // Remove from deselected if it was there
      const deselectedIndex = this.deselectedEvents.indexOf(eventId);
      if (deselectedIndex > -1) {
        this.deselectedEvents.splice(deselectedIndex, 1);
      }
    } else {
      // Remove from selected events
      const selectedIndex = this.selectedEvents.indexOf(eventId);
      if (selectedIndex > -1) {
        this.selectedEvents.splice(selectedIndex, 1);
      }
      // Add to deselected events if it was originally assigned
      if (this.isEventAssigned(eventId)) {
        this.deselectedEvents.push(eventId);
      }
    }
    this.showAssignButton = this.selectedEvents.length > 0;
  }

  isEventAssigned(eventId: string): boolean {
    // Check if assignedEvents and its data exist
    if (!this.assignedEvents?.data) {
      return false;
    }
    return this.assignedEvents.data.some(event => event.id === eventId);
  }



  onRadioChange(event: any): void {
    this.radioButtonValue = event.value;
    if (event.value === 'assign') {
      this.isAssignedEvents = true;
    } else {
      this.isAssignedEvents = false;
    }
    this.assignedEvents.paginator = this.assignedPaginator;
    this.unassignedEvents.paginator = this.unassignedPaginator;

    this.serviceProviderService.getClincianEvents(this.practioner.id).then(data => {
      this.isLoading = true;
      this.assignedEvents = new MatTableDataSource(data?.assignedEvent);
      this.unassignedEvents = new MatTableDataSource(data?.unassignedEvent);
      this.assignedEvents.paginator = this.assignedPaginator;
      this.unassignedEvents.paginator = this.unassignedPaginator;
      this.isLoading = false;
    });
  }


  async assignSelectedEvents(eventIds: string[]) {
    this.isLoading = true;
    // Get currently assigned event IDs
    const assignedEventIds = this.getAssignedEventIds();

    // Combine selected and assigned events, removing duplicates and deselected events
    const allEventIds = [...new Set([...assignedEventIds, ...eventIds])]
      .filter(id => !this.deselectedEvents.includes(id));
    let clincian;
    if (this.practioner)
      clincian = this.practioner;
    else if (this.assistant)
      clincian = this.assistant;
    await this.serviceProviderService.upddateAssignEvent(clincian.id, allEventIds).then(data => {
      this.isLoading = false;
      this.showAssignButton = false;
      //this.selectedEvents = [];
      //this.ngOnInit();
      //this.router.navigate(['/sp-access-management']);
      this.LoadGrid();
    }).catch(err => {
      console.error('Error assigning events to service provider:', err);
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Could not get health practioner to assign events',
      });
    });
  }

}
