import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportService } from 'src/app/shared/services/support.service';
import { WellnessReportService } from 'src/app/shared/services/wellness-report.service';
import { millenialIdNumberValidtor } from 'src/app/shared/validators/millenial-id-number.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'move-event',
  templateUrl: './move-event.component.html',
  styleUrls: ['./move-event.component.scss']
})
export class MoveEventComponent implements OnInit {
  isLoading: boolean = false;
  signupForm!: FormGroup;
  companyNames: any;
  selectedCompanyNames: any;
  moveGrid: any = [];
  columnNames: string[] = ['RSA ID/Passport Number', 'Status'];
  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private eventNames: WellnessReportService, private supportService: SupportService) {
    this.createForm();
    this.isLoading = true;
    this.eventNames.getEventNames().subscribe((data) => {
      this.companyNames = data.sort((a, b) => a.description.localeCompare(b.description));
      this.isLoading = false;
    });


  }

  ngOnInit(): void {
  }

  goToLanding() {
    this.router.navigate(['../wellness-corporate/landing'], { relativeTo: this.route });
  }

  createForm() {
    this.signupForm = this.fb.group({
      currentCompanyId: [null, Validators.required],
      newCompanyId: [null, Validators.required],
      idNumber: ['', [Validators.required]],
    })
  }

  selectOption(event: any) {
    console.log(event.value);
    this.selectedCompanyNames = event.value;
  }

  async assignEvent() {
    if (this.signupForm.valid) {
      this.isLoading = true;
      let d = this.signupForm.getRawValue();
      await this.supportService.moveEvent(d).then((data) => {
        console.log(data);
        let obj;
        if (data.data.results)
          obj = this.transformData(data.data.results);
        else
          obj = {};
        this.moveGrid = new MatTableDataSource<any>(obj);
        this.signupForm.reset();
        this.signupForm.markAsPristine();
        this.signupForm.markAsUntouched();
        this.isLoading = false;
        Swal.fire({
          title: 'Member Assigned',
          text: 'Member/s assigned to Event',
          icon: 'success',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.signupForm.reset();
        this.signupForm.markAsPristine();
        this.signupForm.markAsUntouched();
        this.signupForm.markAsPristine();
        Swal.fire({
          title: 'Member Assignment Error',
          text: 'Error while assigning member to event',
          icon: 'error',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
      })
    }
  }
  // Method to transform the input data
  transformData(data) {
    const result = Object.keys(data).map(key => ({
      idnumber: key,
      status: data[key]
    }));
    return result;
  }
}
