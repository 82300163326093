<three-bar-loader *ngIf="isLoading"></three-bar-loader>
<h4>Edit Member Claim</h4>
<mat-dialog-content>
  <form [formGroup]="editForm">
    <div class="row">
      <mat-form-field>
        <mat-label>Member Number</mat-label>
        <input matInput formControlName="memberNumber">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Dependent Code</mat-label>
        <input matInput formControlName="dependantCode">
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Scheme Code</mat-label>
      <input matInput formControlName="schemeCode">
    </mat-form-field>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>