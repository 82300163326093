<article class="grid" *ngIf="!isLoading">
  <div class="grid__inner grid__inner--layout-12">
    <div class="grid__cell grid-cell-style">
      <div class="row row-style">
        <div class="vertical-align-top">
          <div class="eapImage">&nbsp;</div>
          <div class="inline-block vertical-align-top" *ngIf="mode == 'consultation'">
            <h6 class="mdc-typography--headline5 headline-style" *ngIf="consultationDetail">
              {{consultationDetail.patientDetail.fullNames}} {{consultationDetail.patientDetail.surname}}
            </h6>
            <div class="center-text">
              <div class="inline-block line-height-1-5">{{consultationDetail.patientDetail.rsaidnumber}}</div>
            </div>
            <div>
              <div class="inline-block line-height-1-5">&nbsp;</div>
            </div>
          </div>
        </div>
        <div
          class="cell--align-bottom align-text-bottom mdc-typography--body-1 vertical-align-bottom position-absolute right-0 bottom-0 text-align-right"
          id="consultationDate">
          <div class="line-height-1-5">Wellness event</div>
          <div class="line-height-1-5">{{event.eventDate | date: 'dd MMMM yyyy'}}</div>
          <div class="line-height-1-5">{{eventStartTime | date: 'hh:mm a'}} - {{ eventEndTime | date: 'hh:mm a'}}
          </div>
          <div class="line-height-1-5">{{eventDescription}}</div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</article>
