import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';

import * as Enums from '../../shared/enums';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class KeycloakrestapiService {

  httpOptions: any = {}
  sessionId: string;

  constructor(
    private globalService: GlobalDataService,
    private http: HttpClient
  ) {
    this.httpOptions = globalService.getHttpOptions();

  }

  userByEmail(email: string): Observable<any> {
    return this.http.get(Enums.API_PATH + Enums.KeyclooakEndpoints.GET_USER_BY_EMAIL + '/' + email).pipe(
      tap(data => {
        console.log("Get User By Email:", data);
      }),
      catchError(this.handleError('Unable to get keycloak user via email', undefined))
    );
  }

  userSession(userId: string): Observable<any> {
    return this.http.get(Enums.API_PATH + Enums.KeyclooakEndpoints.GET_USER_SESSION + '/' + userId).pipe(
      tap(data => {
        console.log("Get User Session:", data);
      }),
      catchError(this.handleError('Uable to get user session', undefined))
    );
  }

  deleteUserSession(sessionID: string): Observable<any> {
    return this.http.get(Enums.API_PATH + Enums.KeyclooakEndpoints.DELETE_USER_SESSION + '/' + sessionID).pipe(
      tap(data => {
        console.log("Delete User Session:");
      }),
      catchError(this.handleError('Uable to delete user session', undefined))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }
}
