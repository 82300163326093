import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-landing',
  templateUrl: './support-landing.component.html',
  styleUrls: ['./support-landing.component.scss']
})
export class SupportLandingComponent implements OnInit {

  isLoading: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

}
