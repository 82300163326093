<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <!-- <div style="position: absolute; left: 6%;" (click)="goToLanding()">
      <a href="#" class="previous round"><i style="font-size:24px" class="fa">&#xf015;</i></a>

    </div> -->
    <div>
      <h1 class="mdc-typography--headline2 text--align-center">Changing Consents</h1>
    </div>
  </section><br><br>
  <article class="grid">
    <form [formGroup]="signupForm">
      <div style="display: flex; align-items: center; gap: 20px;">
        <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
          style="width: 25%;">
          <mat-label>South African ID</mat-label>
          <input matInput type="text" formControlName="idNumber" required placeholder="e.g. 8701015009087"
            maxlength="25">
          <mat-error *ngIf="signupForm.get('idNumber').errors?.required">ID or Passport is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker
          style="width: 25%;">
          <mat-label>Select the Event or Company</mat-label>
          <mat-select formControlName="companyId" (selectionChange)="selectOption($event)" #EventSelect>
            <mat-option *ngFor="let event of companyNames" [value]="event.id">{{event.description}}</mat-option>
          </mat-select>
        </mat-form-field>


        <!-- <button class="claimProcess" (click)="showAssessments()">Show assessments</button> -->
        <!-- <button class="claimProcess" (click)="modifyAssessments()">Change consent</button> -->
        <button
          [ngClass]="{ 'mdc-button--raised': signupForm.valid && signupForm.dirty, 'mdc-ripple-upgraded': signupForm.valid && signupForm.dirty, 'mdc-button__fill--darkblue': signupForm.valid && signupForm.dirty, 'mdc-button': signupForm.valid && signupForm.dirty, 'mat-raised-button': signupForm.invalid || signupForm.pristine }"
          (click)="showAssessments()" [disabled]="signupForm.invalid || signupForm.pristine" id="saveButton"
          style="vertical-align: middle; margin-top: -2%;">Show assessments</button>

        <button
          [ngClass]="{ 'mdc-button--raised':  enableConsent, 'mdc-ripple-upgraded': enableConsent, 'mdc-button__fill--red':  enableConsent, 'mdc-button': enableConsent, 'mat-raised-button': !enableConsent }"
          (click)="modifyAssessments()" [disabled]="!enableConsent" id="saveButton"
          style="vertical-align: middle; margin-top: -2%;">Change Consent</button>
      </div>

    </form>

    <div class="mat-elevation-z8 assessment-table" style="width: 45%; ">

      <table mat-table [dataSource]="dataSource" class="w-full">
        <ng-container matColumnDef="Tests">
          <th mat-header-cell *matHeaderCellDef class="text-left"> Assessments Performed </th>
          <td mat-cell *matCellDef="let element" class="text-left"> {{element.workflowName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>

    <div class="mat-elevation-z8 consent-table" style="width: 40%;">
      <table mat-table [dataSource]="consentOptions" class="w-full">
        <ng-container matColumnDef="Add">
          <th mat-header-cell *matHeaderCellDef class="text-left"> Select </th>
          <td mat-cell *matCellDef="let element" class="text-left">
            <mat-checkbox (change)="onConsentChange(element.id, $event.checked)">
            </mat-checkbox>
          </td>
        </ng-container>


        <ng-container matColumnDef="Assessment">
          <th mat-header-cell *matHeaderCellDef class="text-left"> Assessments </th>
          <td mat-cell *matCellDef="let element" class="text-left"> {{element.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="consentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: consentColumns;"></tr>
      </table>
    </div>

  </article>



</div>