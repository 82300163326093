import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

import { EditMemberDailogComponent } from '../view-members/edit-member-dailog/edit-member-dailog.component';

@Component({
  selector: 'app-view-members',
  templateUrl: './view-members.component.html',
  styleUrls: ['./view-members.component.scss']
})

export class ViewMembersComponent implements OnInit {
  @Input() companyClaims: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading: boolean = false;
  pagesize = 0;
  rejectedClaimsData: any = [];
  displayedColumns: string[] = ['patientRefNumber', 'firstName', 'surname', 'reason', 'action'];
  dataSource: MatTableDataSource<any>;
  constructor(private claimService: ClaimsService, private router: Router, private route: ActivatedRoute, private globalData: GlobalDataService, private dialog: MatDialog) {
    this.globalData.updateBackRoute('/wellness-event/corporate/fix-claim');
  }

  async ngOnInit(): Promise<void> {
    await this.getRejectedClaimMembers();
  }

  async getRejectedClaimMembers() {
    this.isLoading = true;
    if (history?.state) {
      this.claimService.getRejectedCLaimMembers(history.state['object'].id).then(data => {
        this.rejectedClaimsData = data.map(claim => ({
          patientRefNumber: claim.cr.patientRefNumber,
          firstName: claim.cr.patientFirstName,
          surname: claim.cr.patientSurname,
          reason: claim.cr?.reason,
          data: claim.cr
        }));
        this.dataSource = new MatTableDataSource(this.rejectedClaimsData);
        this.dataSource.paginator = this.paginator;
        this.pagesize = this.rejectedClaimsData.length;
        this.isLoading = false;
      }).catch(error => {
        console.log("Error fetching the Members for rejected claims", error);
        this.isLoading = false;
      });
    }
  }

  goToClaimLanding() {
    this.router.navigate(['../landing'], { relativeTo: this.route });
  }

  editMemberClaim(object: any) {
    const dialogRef = this.dialog.open(EditMemberDailogComponent, {
      width: '500px',
      data: object // Pass the rejected claim data to dialog
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle dialog close with updated data if needed
        this.getRejectedClaimMembers();
      }
    });
  }



}
