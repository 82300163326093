<!-- <article class="grid">
  <p>Work in Progress ..</p>
  <button (click)="claim()">Mediscor Claim API</button>
</article> -->
<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>
    <!-- <three-bar-screen [loading]="isLoading === true"></three-bar-screen> -->
    <div *ngIf="isFailed === true">
      <section class="mdc-typography--body1">
        <app-back-button [route]="financial"></app-back-button>
        <h1 class="mdc-typography--headline2 text--align-center">Summary</h1>
      </section>
      <span class="blank-line blank-line-2 d-block"></span>
      <p class="text--align-center mdc-typography--body1">An error occurred.</p>
      <p class="text--align-center mdc-typography--body1">Please go back to the previous page.</p>
      <section class="grid__inner">
        <div class="mb-1">
          <span class="blank-line blank-line-4 d-block"></span>
        </div>
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Return to previous page"
                    (click)="previousPage()">
                    Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="isFailed === false">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Finance management</h1>
      </section>
      <div class="grid__inner grid__inner--layout-b8b">
        <!-- <div class="grid__cell">
          <span class="blank-line blank-line-2 d-block"></span>
          <app-corporate-wellness-info-header [mode]="event"></app-corporate-wellness-info-header>
        </div> -->
        <div class="grid__cell">
          <div class="test-list"> <!--this tag we can use to implement for loop-->
            <ng-container>
              <div class="row">
                <div class="grid__cell" style="text-align: -webkit-center">
                  <img *ngIf="true" class="" style="height: 40px" src="assets/img/consultation/not_completed.svg"
                    alt="ico">
                  <!-- <img *ngIf="tests.status == 'finished'" class="" style="height: 40px" src="assets/img/consultation/completed.svg"
                  alt="ico">
                <img *ngIf="tests.status == 'inProgress'" class="" style="height: 40px" src="assets/img/consultation/in_progress.svg"
                  alt="ico"> -->
                  <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                </div>
                <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                  <div class="row">
                    <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                      <img class="" style="height: 40px" src="assets\img\consultation\consent.svg" alt="ico">
                    </div>
                    <div style="width: 90%" class="grid__cell">
                      <div class="grid__cell mdc-typography--subtitle1">
                        <span>Claims submission</span>
                        <!-- <span>Claim 1</span> -->
                      </div>
                    </div>
                    <!-- <div style="width: 90%" class="grid__cell">
                    <div class="grid__cell mdc-typography--subtitle1">
                      <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short"
                        style="margin-left: 30px"
                        [ngClass]="{ 'progressButton_failed': tests.testStatusFailed, 'progressButton_intermediate': tests.testStatusIntermediate, 'progressButton_pass': tests.testStatusPass, 'progressButton_error' : tests.testStatusError}">
                        {{tests.testsPerformed[0].resultInEnglish}}
                      </button>
                    </div>
                  </div> -->
                    <div class="grid__cell" style="width: 20%">
                      <button style="width: 150px;"
                        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                        (click)="claimClickEvent()">Open</button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <!--Invoicing start from here-->
              <div class="row">
                <div class="grid__cell" style="text-align: -webkit-center">
                  <img *ngIf="true" class="" style="height: 40px" src="assets/img/consultation/not_completed.svg"
                    alt="ico">
                  <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                </div>
                <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                  <div class="row">
                    <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                      <img class="" style="height: 40px" src="assets\img\consultation\questions.svg" alt="ico">
                    </div>
                    <div style="width: 90%" class="grid__cell">
                      <div class="grid__cell mdc-typography--subtitle1">
                        <span>Invoicing</span>
                      </div>
                    </div>
                    <div class="grid__cell" style="width: 20%">
                      <button style="width: 150px;"
                        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                        (click)="invoiceClickEvent()">Start</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--Invoicing end from here-->
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </article>
</div>