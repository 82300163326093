import 'sweetalert2'

import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit {

  isLoading = false;
  isFailed = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private globalData: GlobalDataService,
  ) {
    this.globalData.updateBackRoute('/wellness-event/corporate/landing');
  }

  ngOnInit(): void {
  }

  claim() {
    this.isLoading = true;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })

    this.http.get('https://mediscor-benefit-api.onrender.com/claimdemo', { headers }).subscribe(data => {
      console.log("Mediscor Claim Data", data);
      this.isLoading = false;
    })
  }

  previousPage(): void {
    this.router.navigate(['../landing'], { relativeTo: this.route });
  }


  claimClickEvent() {
    this.router.navigate(['../claim-landing'], { relativeTo: this.route });
  }

  invoiceClickEvent() {
    Swal.fire("Invoicing is Under construction");
  }

}
