import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { KeycloakrestapiService } from 'src/app/shared/services/keycloakrestapi.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {

  constructor(private keycloakRest: KeycloakrestapiService, private globalData: GlobalDataService, private keycloak: KeycloakService) {

  }

  logout() {
    if (window.location.origin.indexOf('localhost') > 0) {
      this.keycloak.logout(window.location.origin + this.globalData.appDefaultPath());
    }
    else {
      this.keycloak.logout(window.location.origin + "/perigon/#" + this.globalData.appDefaultPath());
    }
    //this.isLoggedIn = false;
  }

  ngOnInit(): void {

    // Query the deletion of the session, when released what if more than one session is logged in?
    if (this.keycloakRest.sessionId) {
      this.keycloakRest.deleteUserSession(this.keycloakRest.sessionId).subscribe(data => {
        Swal.fire({
          title: `Logged in on another device`,
          html: "You will now be logged out of this device, to continue on this device, you will need to log out of the other device.",
          icon: 'warning',
          iconColor: 'red',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          showCancelButton: false,
          allowOutsideClick: () => {
            this.logout();
            return false;
          },
          preConfirm: () => {
            this.logout();
          }
        });

        console.log("Successfully deleted the user's session:");
      },
        error => {
          console.log("Error for deleting the User session ", error);
        });
    }
    setTimeout(() => {
      this.logout();
    }, 8000);

  }
}