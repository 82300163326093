import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators';

import * as Enums from '../enums';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsReportService {

  httpOptions: any = {};


  constructor(public http: HttpClient, private globalServ: GlobalDataService) { }

  GetClaimsReport(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.DownloadClaimsReport, { dateFrom: startDate, dateTo: endDate, serviceProvider: serviceProviderIds, companyName: eventIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  SearchClaimsReport(serviceProviderIds: string[], startDate: string, endDate: string, eventIds: string[], paymentIds: string[], coordinator: string[]): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions()

    return this.http
      .post(Enums.API_PATH + Enums.API_PATHS.SearchClaimsReport, { dateFrom: startDate, dateTo: endDate, serviceProvider: serviceProviderIds, paymentIds: paymentIds, companyName: eventIds, coordinatorName: coordinator }, this.httpOptions)
      .pipe(
        tap((data) => console.log('Ping')),
        catchError(this.handleError('Unable to get event summary', undefined))
      )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error)
      this.log(`${operation} failed: ${error.message}`)
      return of(result as T)
    }
  }

  private log(message: string) {
    console.log(`${message}`)
  }




}
