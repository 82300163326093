import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const millenialIdNumberValidtor: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  let idNumber = control.value?.toString() || '';

  if (!idNumber) {
    return null;
  }

  if (idNumber.length > 13) {
    return { 'invalidIdLength': true };
  }

  if (idNumber.length < 13) {
    idNumber = idNumber.padStart(13, '0');
  } else if (idNumber.length > 13) {
    return { 'invalidIdLength': true };
  }

  // Extract year digits from ID number
  const yearDigits = idNumber.substring(0, 2);
  const monthDigits = idNumber.substring(2, 4);
  const dayDigits = idNumber.substring(4, 6);

  // Convert to full year - updated logic for 2000s
  const currentYear = new Date().getFullYear();
  const century = parseInt(yearDigits) > (currentYear - 2000) ? 19 : 20;
  const fullYear = century * 100 + parseInt(yearDigits);

  // Validate date
  const date = new Date(fullYear, parseInt(monthDigits) - 1, parseInt(dayDigits));
  const isValidDate = date instanceof Date && !isNaN(date.getTime()) &&
    date.getMonth() === parseInt(monthDigits) - 1 &&
    date.getDate() === parseInt(dayDigits);

  if (!isValidDate) {
    return { 'invalidDateOfBirth': true };
  }

  // Luhn algorithm check
  const digits = idNumber.split('').map(Number);
  let sum = 0;
  let isSecond = false;

  for (let i = digits.length - 1; i >= 0; i--) {
    let d = digits[i];
    if (isSecond) {
      d *= 2;
      if (d > 9) d -= 9;
    }
    sum += d;
    isSecond = !isSecond;
  }

  if (sum % 10 !== 0) {
    return { 'invalidIdNumber': true };
  }


}