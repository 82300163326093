<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <!-- <div style="position: absolute; left: 6%;" (click)="goToLanding()">
      <a href="#" class="previous round"><i style="font-size:24px" class="fa">&#xf015;</i></a>

    </div> -->
    <div>
      <h1 class="mdc-typography--headline2 text--align-center">Move to Event</h1>
    </div>
  </section><br><br>
  <article class="grid">
    <p><strong>Note: </strong><i>Separate multiple ID/Passport numbers with a comma, for example
        7509250159085,ZA0124569. Members can be assigned to 1
        event at a time</i></p>
    <form [formGroup]="signupForm">
      <div style="display: flex; align-items: center; gap: 55px;">
        <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
          style="width: 25%;">
          <mat-label>RSA ID/Passport Number</mat-label>
          <textarea matInput formControlName="idNumber" required
            placeholder="Enter RSA ID or Passport Number"></textarea>
        </mat-form-field>

        <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker
          style="width: 25%;">
          <mat-label>Select the Current Event</mat-label>
          <mat-select formControlName="currentCompanyId" (selectionChange)="selectOption($event)" #EventSelect>
            <mat-option *ngFor="let event of companyNames" [value]="event.id">{{event.description}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker
          style="width: 25%;">
          <mat-label>Select the Event to assign</mat-label>
          <mat-select formControlName="newCompanyId" (selectionChange)="selectOption($event)" #EventSelect>
            <mat-option *ngFor="let event of companyNames" [value]="event.id">{{event.description}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-raised-button class="button claimProcess" (click)="assignEvent()"
          [disabled]="signupForm.invalid">Assign
          Event</button>
      </div>

    </form>


    <div class="mat-elevation-z8 gridtable">
      <table mat-table [dataSource]="moveGrid" class="w-full">

        <ng-container matColumnDef="RSA ID/Passport Number" width="35%">
          <th mat-header-cell *matHeaderCellDef class="text-left"> RSA ID/Passport Number </th>
          <td mat-cell *matCellDef="let element"> {{element.idnumber}} </td>
        </ng-container>

        <ng-container matColumnDef="Status" width="35%">
          <th mat-header-cell *matHeaderCellDef class="text-left"> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
      </table>
    </div>

  </article>
</div>